.m-center-wrap {
	width: 100%;
	height: 90vh;
	min-height: 440px;
	max-height: 900px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.m-logo-section {
	position: relative;
}

.instructions-wrap {
	position: relative;
	top: -10vh;
	text-align: center;
}

.m-instructions {
	opacity: 0.35;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.075rem;
	cursor: pointer;
}

.m-logo-wrap {
	width: 240px;
	height: 240px;
	cursor: pointer;
	margin: 0 auto;
	margin-bottom: 24px;
	display: block;
	position: relative;
}
