html {
	background-color: $bg-color;
	overflow-x: hidden;
}

body {
	margin: 0;
	overflow-x: hidden;
}

svg {
	width: 100%;
	display: block;
	overflow: visible;
}

svg:not(:root) {
	overflow: visible;
}

a.no-outline:focus {
	outline: none;
}

.page-content {
	--page-color: white;
	margin: 0;
	min-height: 100vh;
}

.main-footer {
	text-align: center;
	position: relative;
	margin: 0 auto;
	margin-top: 30px;
	padding-bottom: 10px;
}

.my-name {
	opacity: 0;
	animation: fadeIn 0.75s $ease-in-quad 0.5s forwards;
	text-align: center;
	letter-spacing: 0.03em;
	margin-bottom: 8px;
}

.my-description {
	opacity: 0;
	animation: fadeIn 0.75s $ease-in-quad 0.7s forwards;
	text-align: center;
	color: rgba(255, 255, 255, 0.65);
	font-size: 1.2rem;
	font-weight: 300;
	margin-bottom: 20px;
}

.button--themed {
	position: relative;
	z-index: 1;
	display: block;
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	color: $bg-color;
	text-decoration: none;
	width: 180px;
	height: 40px;
	margin: 0 auto;
	padding: 0 $spacing-8;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: var(--page-color);
	box-shadow: 0 0 0 transparent;
	transition: all 0.25s ease-out;

	&:before,
	&:after {
		content: '';
		background-color: var(--page-color);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all 0.25s $ease-out-back;
	}

	&:before {
		opacity: 0.15;
		left: 16px;
		right: 16px;
		transform: translate(0, 10px);
	}

	&:after {
		opacity: 0.35;
		left: 8px;
		right: 8px;
		transform: translate(0, 5px);
	}

	&:hover,
	&:focus {
		outline: 0;

		&:before {
			top: -16px;
			left: -16px;
			right: -16px;
			bottom: -16px;
			transform: translate(0, 0);
		}

		&:after {
			top: -8px;
			left: -8px;
			right: -8px;
			bottom: -8px;
			transform: translate(0, 0);
		}
	}

	&:active {
		&:before {
			top: -32px;
			left: -32px;
			right: -32px;
			bottom: -32px;
			transform: translate(0, 0);
		}

		&:after {
			top: -16px;
			left: -16px;
			right: -16px;
			bottom: -16px;
			transform: translate(0, 0);
		}
	}

	.button__text {
		z-index: 10;
		position: relative;
	}
}

//PAGE HEADER

.site-header {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	padding: $spacing-10;
	transition: all 0.15s $ease-out-quad;
	.m-logo-wrap {
		transition: all 0.15s $ease-out-quad;
		margin: 0;
		width: 50px;
		height: 50px;
	}
	@include mq($until: tablet) {
		padding: $spacing-7;
		.m-logo-wrap {
			width: 40px;
			height: 40px;
		}
	}
}

//SECTIONS

.section-wrap {
	margin: 0 auto;
	margin-bottom: $spacing-11 * 2;
}

.landing-section-header {
	text-align: center;
	margin-bottom: $spacing-11;
}

.section-title {
	font-size: 2.5rem;
	font-weight: 700;
}

//FOOTER

.social-links-wrap {
	opacity: 0;
	animation: fadeIn 0.75s $ease-in-quad 0.9s forwards;
}

.email-me {
	margin: 1.25rem auto;
	margin-top: 1rem;
}

.footer-text {
	font-size: 0.8em;
	opacity: 0.75;
	color: white;
}

.link-icon-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
}

.link-icon {
	width: 20px;
	display: inline-block;
	transition: all 0.2s ease-out;

	&:not(:last-child) {
		margin-right: 10px;
	}

	.icon-fill {
		fill: var(--page-color);
	}

	&:hover,
	&:focus {
		transform: scale(1.25);
	}

	&:focus {
		outline: 1px solid var(--page-color);
		outline-offset: 2px;
	}
}

//HOVER GIFS

.gif-hover {
	cursor: pointer;
	position: relative;
	white-space: nowrap;
	font-weight: 500;

	&:before {
		position: absolute;
		content: '';
		opacity: 0;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%) scale(1, 0);
		box-shadow: inset 0 0 50px 30px $bg-color, inset 0 0 0 5px $bg-color;
		border-radius: 20px;
		transition: all 0.35s $ease-out-back;
		z-index: -2;
		pointer-events: none;
	}

	&:after {
		content: '';
		background-color: transparentize($primaryBlue, 0.6);
		position: absolute;
		z-index: -1;
		bottom: 0.15em;
		left: 0;
		right: 0;
		height: 0.25em;
		transition: all 0.15s ease-out;
	}

	&:hover {
		&:before {
			opacity: 1;
			transform: translate(-50%, -50%) scale(1, 1);
			transition-duration: 0.2s;
		}

		&:after {
			height: 1em;
		}
	}
}

.gif-hover-mac {
	&:before {
		width: 388px;
		height: 291px;
		background-image: url('/assets/gifs/1984mac.gif');
	}
}

.gif-hover-topgun {
	&:before {
		width: 480px;
		height: 241px;
		background-image: url('/assets/gifs/topgun.gif');
	}
}

.gif-hover-sesame {
	&:before {
		width: 480px;
		height: 360px;
		background-image: url('/assets/gifs/sesame.gif');
	}
}
