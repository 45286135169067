.beliefs-list-wrap {
	width: 1024px;
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	@include mq($until: tablet) {
		flex-wrap: nowrap;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
	}
}

.belief-item {
	padding: 0 $spacing-10;
	&:not(:last-child) {
		margin-bottom: $spacing-11;
	}
	width: 280px;
	flex-grow: 1;
	@include mq($until: tablet) {
		min-width: 320px;
		padding: 0 $spacing-7;
	}
}

.belief-item-header {
	font-size: 1.25rem;
	margin-bottom: 10px;
}

.belief-item-copy {
	font-size: 1rem;
	margin: 0;
	opacity: 0.85;
}
