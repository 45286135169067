$primaryBlue: rgb(0, 180, 204);
$primaryRed: rgb(237, 35, 50);
$bg-color: rgb(34, 45, 56);
$bg-dark: rgb(23, 31, 38);

// Grays
// -------------------------
$black: rgb(0, 0, 0);

$white: rgb(255, 255, 255);

//Translucent

$translucent-white: rgba(255, 255, 255, 0.85);
$translucent-black: rgba(0, 0, 0, 0.85);

// Default colors
// -------------------------

// Links
// -------------------------
// $linkColor:         $teal;

// SPACINGS

$spacing-7: 1.25rem;
$spacing-10: 2rem;
$spacing-11: 2.5rem;

$spacing-1: 0.125rem;
$spacing-2: 0.25rem;
$spacing-3: 0.375rem;
$spacing-4: 0.5rem;
$spacing-5: 0.75rem;
$spacing-6: 1rem;
$spacing-7: 1.25rem;
$spacing-8: 1.5rem;
$spacing-9: 1.75rem;
$spacing-10: 2rem;
$spacing-11: 2.5rem;
$spacing-12: 3rem;
$spacing-13: 3.5rem;
$spacing-14: 4rem;
$spacing-15: 4.5rem;
$spacing-16: 5rem;
$spacing-17: 6rem;
$spacing-18: 7rem;
$spacing-19: 8rem;
$spacing-20: 9rem;

// STRUCTURE WIDTHS

$centerMaxWidth: 51rem;

// Z-Indices
// -------------------------
$zSVG: 50;
$zStandard: 100;
$zMiddle: 500;
$zBelowNav: 999;
$zNav: 1000;
$zMainNav: 1001;
$zTop: 9999;

// Shadows

$shadowSm: 0 5px 15px rgba(0, 0, 0, 0.5);
$shadowMd: 0 5px 30px rgba(0, 0, 0, 0.5);

// Easings

$ease-in: cubic-bezier(0.42, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.58, 1);
$ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);

$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);

$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
