.m-logo-random-line {
  line {
    stroke: white;
    stroke-width: 1;
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: dashOffset 1.2s $ease-in-quad forwards;
  }
}

.site-header {
  .m-logo-random-line {
    line {
      stroke-width: 0.5;
    }
  }
}

@keyframes dashOffset {
  to {
    stroke-dashoffset: 0;
  }
}
