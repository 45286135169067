.work-header-wrap {
	width: $centerMaxWidth;
	max-width: 100%;
	margin: 10vh auto;
	padding: 0 $spacing-10;
}

.work-header {
	background-color: $bg-dark;
	border: 1px solid var(--page-color);
}

.work-header-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: $spacing-8;
	padding: $spacing-8;
	border-bottom: 1px solid var(--page-color);

	@include mq($until: tablet) {
		flex-direction: column;
	}
}

.work-header-bottom {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-bottom: -1px;
	margin-right: -1px;
}

.work-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	z-index: -1;
	opacity: 0.25;
	background-image: radial-gradient(circle at top, var(--page-color), $bg-color 65%);
	background-size: 100% 300%;
	background-position-y: 60%;
}

.work-title-wrap {
	height: 3rem;
	max-width: 15rem;
}

.work-title {
	font-size: 2.2em;
	text-align: center;
}

.work-logo {
	display: block;
	height: 100%;
	max-width: 240px;
	max-height: 48px;
}

.work-link {
	height: 3rem;
	width: 8rem;
	margin: 0;
	text-decoration: none;
}

.work-detail {
	background-color: $bg-color;
	height: 3rem;
	display: flex;
	justify-content: center;
	width: fit-content;
	align-items: center;
	border-bottom: 1px solid var(--page-color);
	border-right: 1px solid var(--page-color);
	padding: 0 $spacing-8;
	color: var(--page-color);
	font-size: 0.875rem;
	font-weight: 400;
	text-align: center;

	@include mq($until: tablet) {
		flex-grow: 1;
		padding: 0 $spacing-5;
	}
}

.work-item-list {
	margin: 0 auto;
	max-width: $centerMaxWidth;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
}

.work-item-wrap {
	flex-grow: 1;
	max-width: 400px;
	min-width: 300px;
	height: 200px;
	margin: 0 $spacing-7;
	margin-bottom: $spacing-11;
	position: relative;
	perspective: 600px;
	perspective-origin: 50% 200%;
	transform-style: preserve-3d;
	@include mq($until: tablet) {
		margin: 0 $spacing-10;
		margin-bottom: $spacing-10;
		height: 150px;
	}
	&:hover {
		.work-item {
			transform: translate3d(0, 0, 20px);
		}
		.work-item-stack {
			background-color: var(--page-color);
			&:nth-child(1) {
				opacity: 0.1;
				transform: translate3d(0, 0, -60px);
			}
			&:nth-child(2) {
				opacity: 0.2;
				transform: translate3d(0, 0, -40px);
			}
			&:nth-child(3) {
				opacity: 0.3;
				transform: translate3d(0, 0, -20px);
			}
			&:nth-child(4) {
				opacity: 0.4;
				transform: translate3d(0, 0, 0px);
			}
		}
	}
}

.work-item-stack {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: 0;
	background-color: var(--page-color);
	transform-origin: 50% 100%;
	transition: all 0.3s $ease-out-back;

	&:nth-child(1) {
		opacity: 0.2;
		transform: translate3d(0, 0, -30px);
	}

	&:nth-child(2) {
		opacity: 0.4;
		transform: translate3d(0, 0, -15px);
	}
}

.work-item {
	background-color: var(--page-color);
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 10px;
	text-align: center;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
	overflow: hidden;
	transition: all 0.3s $ease-out-back;

	&:before,
	&:after {
		content: '';
		position: absolute;
		z-index: -1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 1;
		background: radial-gradient(at 15% 0%, transparent, rgba(0, 0, 0, 0.15));
		transition: all 0.5s $ease-out-quad;
	}

	&:after {
		opacity: 0;
		background: radial-gradient(at 15% 0%, rgba(255, 255, 255, 0.25), transparent);
	}

	&:hover {
		&:before {
			opacity: 0;
		}

		&:after {
			opacity: 1;
		}
	}
}

.work-item-contents {
	width: 100%;
	transition: all 0.2s ease-in;
}

.work-item-logo {
	display: block;
	width: 100%;
	max-width: 15rem;
	max-height: 3rem;
	margin: 0 auto;
}

.work-item-title {
	display: block;
	font-size: 1.6em;
	font-weight: 300;
	text-align: center;
}

.work-item-details {
	display: block;
	color: rgba(255, 255, 255, 0.75);
}

.skill-section {
	align-items: stretch;
	flex-wrap: wrap;
	gap: $spacing-10;
	margin-block: $spacing-20;

	@include mq($until: tablet) {
		gap: $spacing-8;
	}

	.skill-panel {
		background-color: $bg-dark;
		border: 1px solid var(--page-color);
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		width: 20rem;
	}
}

.skill-list-header {
	padding: $spacing-8;
	color: var(--page-color);
}

.skill-list {
	display: flex;
	flex-wrap: wrap-reverse;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 100%;
	list-style: none;
	padding: 0;
	margin-top: auto;
}

.skill-list-item {
	background: $bg-color;
	display: flex;
	align-items: center;
	height: 2rem;
	padding: 0 $spacing-5;
	border: 1px solid var(--page-color);
	margin-left: -1px;
	margin-bottom: -1px;
}

.skill-list-item-text {
	color: var(--page-color);
	font-weight: 500;
}
